import React, { ReactElement } from "react";
import clsx from "clsx";
import { ErrorMessage } from "formik";

type FormFieldProps = {
  readonly name: string;
  readonly className?: string;
  readonly children: ReactElement;
};

export default function FormField({
  name,
  className,
  children,
}: FormFieldProps): ReactElement {
  return (
    <div className={clsx(["form-group", className])}>
      {children}
      <ErrorMessage name={name} component="div" className="form-status error" />
    </div>
  );
}
