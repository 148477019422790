import React, { ReactElement } from "react";
import { useFormikContext } from "formik";
import FormSuccess from "./form-success";
import FormError from "./form-error";

type FormStatusProps = {
  readonly success: string;
};

export default function FormStatus({ success }: FormStatusProps): ReactElement {
  const { status } = useFormikContext();
  return (
    <>
      {status === true && <FormSuccess>{success}</FormSuccess>}
      {status === false && (
        <FormError>
          There was an error submitting the form, please try again later
        </FormError>
      )}
    </>
  );
}
