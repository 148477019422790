import React, { ReactElement } from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  GatsbyMarkdownFile,
  GatsbyMarkdownFilesCollection,
  MarkdownContent,
} from "@ymcansw-camping/common";
import { sortBy } from "lodash";
import CommunityLayout from "../../components/community-layout";
import NewsletterForm from "../../components/newsletter-form";
import UpcomingCampBlock, {
  UpcomingCamp,
} from "../../components/upcoming-camp-block";
import FurtherInformationRow from "../../components/further-information-row";

type UpcomingCampsData = {
  readonly pageFile: GatsbyMarkdownFile<{
    readonly callToAction: string;
    readonly furtherInformation: ReadonlyArray<{
      readonly title: string;
      readonly summary: string;
      readonly link: string;
      readonly icon: Readonly<{
        readonly publicURL: string;
      }>;
    }>;
  }>;
  readonly allFile: GatsbyMarkdownFilesCollection<UpcomingCamp>;
};

export default function UpcomingCamps(): ReactElement {
  const {
    allFile,
    pageFile: {
      childMarkdownRemark: { rawMarkdownBody, frontmatter },
    },
  } = useStaticQuery<UpcomingCampsData>(graphql`
    {
      pageFile: file(relativePath: { eq: "pages/upcoming-camps.md" }) {
        childMarkdownRemark {
          rawMarkdownBody
          frontmatter {
            callToAction
            furtherInformation {
              title
              summary
              link
              icon {
                publicURL
              }
            }
          }
        }
      }
      allFile(filter: { relativeDirectory: { eq: "upcoming-camps" } }) {
        nodes {
          id
          childMarkdownRemark {
            frontmatter {
              ...UpcomingCampBlock_event
            }
          }
        }
      }
    }
  `);

  const events = sortBy(
    allFile.nodes
      .map((node) => ({ id: node.id, ...node.childMarkdownRemark.frontmatter }))
      .filter((event) => event.published),
    "dateFrom"
  );
  return (
    <CommunityLayout
      meta={{
        title: "Upcoming Camps",
        description:
          "If you're looking for healthy, active and exciting holiday care for your child, The Y's School Holiday Camp Programs are for you. Hurry and reserve your spot - Limited spaces available.",
      }}
    >
      <div className="content-block">
        <h2>Upcoming Camps</h2>
        <MarkdownContent>{rawMarkdownBody}</MarkdownContent>
        <h3 className="h6">{frontmatter.callToAction}</h3>
      </div>
      {events.map((event) => (
        <UpcomingCampBlock key={event.id} event={event} />
      ))}

      <div className="content-block further-info-container">
        <h3 className="h4">Further Information</h3>
        {frontmatter.furtherInformation.map((info) => (
          <FurtherInformationRow
            key={info.title}
            title={info.title}
            summary={info.summary}
            link={info.link}
            icon={info.icon.publicURL}
          />
        ))}
      </div>
      <NewsletterForm />
    </CommunityLayout>
  );
}
