import * as queryString from "query-string";
import { FormikHelpers, FormikValues } from "formik";
import { useCallback } from "react";

export default function useSubmitFormCallback<T = FormikValues>(
  name: string
): (values: T, actions: FormikHelpers<T>) => Promise<void> {
  return useCallback(
    (values: T, actions: FormikHelpers<T>): Promise<void> => {
      actions.setStatus(undefined);
      return fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: queryString.stringify({ "form-name": name, ...values }),
      })
        .then((response) => {
          if (!response.ok) {
            return response.text().then((text) => {
              throw new Error(text);
            });
          }
          return undefined;
        })
        .then(() => actions.setStatus(true))
        .catch((e) => {
          actions.setStatus(false);
          throw e;
        });
    },
    [name]
  );
}
