/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from "react";
import { useField } from "formik";
import FormField from "./form-field";

type FormFieldInputProps = {
  readonly name: string;
  readonly type: "text" | "email";
  readonly className?: string;
  readonly placeholder: string;
  readonly disabled: boolean;
};

export default function FormFieldInput({
  type,
  name,
  className,
  placeholder,
  disabled,
}: FormFieldInputProps): ReactElement {
  const [field] = useField(name);
  return (
    <FormField name={name} className={className}>
      <input
        className="form-control"
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        {...field}
      />
    </FormField>
  );
}
