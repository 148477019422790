import React, { lazy, ReactElement, Suspense, useState } from "react";
import { isSsr } from "@ymcansw-camping/common";

const LazySweetAlert = lazy(() => import("sweetalert2-react"));

type FormSuccessProps = {
  readonly children: string;
};

export default function FormSuccess({
  children,
}: FormSuccessProps): ReactElement | null {
  const [show, setShow] = useState(true);

  if (isSsr) {
    return null;
  }

  return (
    <Suspense fallback={<></>}>
      <LazySweetAlert
        show={show}
        title="Success"
        type="success"
        text={children}
        onConfirm={() => setShow(false)}
      />
    </Suspense>
  );
}
