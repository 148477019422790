import React, { ReactElement, useCallback, useState } from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import moment from "moment";
import {
  MarkdownContent,
  ArrowLink,
  FixedSharpImageFile,
} from "@ymcansw-camping/common";

export type UpcomingCamp = {
  readonly title: string;
  readonly dateFrom: string;
  readonly dateTo: string;
  readonly duration: string;
  readonly cost: string;
  readonly ageGroups: ReadonlyArray<string>;
  readonly inclusions: string;
  readonly registrationsOpen: string;
  readonly registrationsClose: string;
  readonly additionalInformation: string;
  readonly bookUrl: string;
  readonly published: boolean;
  readonly image: FixedSharpImageFile;
};

type UpcomingCampBlockProps = {
  readonly event: UpcomingCamp;
};

export default function UpcomingCampBlock({
  event: {
    title,
    image,
    dateFrom,
    dateTo,
    duration,
    cost,
    registrationsOpen,
    registrationsClose,
    ageGroups,
    bookUrl,
    inclusions,
    additionalInformation,
  },
}: UpcomingCampBlockProps): ReactElement {
  const [isAdditionalOpen, setAdditionalOpen] = useState(false);
  const onAdditionalClick = useCallback(() => {
    setAdditionalOpen(!isAdditionalOpen);
  }, [isAdditionalOpen]);
  // TODO: Slide additional content

  return (
    <div className="content-block upcoming-camp-block">
      <div className="image-container">
        <Img
          fixed={image.childImageSharp.fixed}
          className="img-fluid"
          alt={title}
        />
        <div className="camp-name-date">
          <span className="date">
            {moment(dateFrom).format("D MMMM YYYY")}
            {" - "}
            {moment(dateTo).format("D MMMM YYYY")}
          </span>
          <h3 className="h4 camp-name">{title}</h3>
        </div>
      </div>
      <div className="ucb-details">
        <div className="ucbd-block">
          <span className="ucbd-block-head">Duration:</span>
          <div className="ucbd-block-content">
            <p>{duration}</p>
          </div>
        </div>
        <div className="ucbd-block">
          <span className="ucbd-block-head">Cost:</span>
          <div className="ucbd-block-content">
            <p>{cost}</p>
          </div>
        </div>
        <div className="ucbd-block">
          <span className="ucbd-block-head">Ages Groups:</span>
          <div className="ucbd-block-content">
            <ul>
              {ageGroups.map((ageGroup) => (
                <li key={ageGroup}>{ageGroup}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="ucbd-block">
          <span className="ucbd-block-head">Inclusions:</span>
          <div className="ucbd-block-content">
            <p>{inclusions}</p>
          </div>
        </div>

        <div className="ucbd-block">
          <span className="ucbd-block-head">Registrations open:</span>
          <div className="ucbd-block-content">
            <p>{moment(registrationsOpen).format("D MMMM YYYY ha")}</p>
          </div>
        </div>

        <div className="ucbd-block">
          <span className="ucbd-block-head">Registration close:</span>
          <div className="ucbd-block-content">
            <p>{moment(registrationsClose).format("D MMMM YYYY ha")}</p>
          </div>
        </div>

        <div className={clsx(["additional-info", { open: isAdditionalOpen }])}>
          <button
            type="button"
            className="additional-info-head"
            onClick={onAdditionalClick}
          >
            Additional Information
            <span className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                <path d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z" />
              </svg>
            </span>
          </button>
          <div
            className="additional-info-content"
            style={{ display: isAdditionalOpen ? "block" : "none" }}
          >
            <MarkdownContent>{additionalInformation}</MarkdownContent>
          </div>
        </div>

        <ArrowLink to={bookUrl}>Book now</ArrowLink>
        <p className="small-text">
          Please note: Payment or Purchase Order number is required upon
          submission of enrolment. Enrolments will not be reviewed until paid in
          full or Purchase Order number provided. All enrolments will be
          reviewed and placements are not confirmed until advised via email.{" "}
        </p>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment UpcomingCampBlock_event on MarkdownRemarkFrontmatter {
    title
    dateFrom
    dateTo
    duration
    cost
    ageGroups
    inclusions
    registrationsOpen
    registrationsClose
    additionalInformation
    bookUrl
    published
    image {
      childImageSharp {
        fixed(width: 788, height: 230) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
