import React, { ReactElement } from "react";
import { ArrowLink } from "@ymcansw-camping/common";

type FurtherInformationRowProps = {
  readonly title: string;
  readonly summary: string;
  readonly icon: string;
  readonly link: string;
};

export default function FurtherInformationRow({
  title,
  summary,
  icon,
  link,
}: FurtherInformationRowProps): ReactElement<FurtherInformationRowProps> {
  return (
    <div className="row further-info-block">
      <div className="col-xs-12 col-md-2">
        <div className="icon-circle-block">
          <span className="icon">
            <img src={icon} alt={title} className="svg" />
          </span>
        </div>
      </div>
      <div className="col-xs-12 col-md-10">
        <div className="fib-content">
          <h6 className="title mt0">{title}</h6>
          <p>{summary}</p>
          <ArrowLink to={link}>Find out more</ArrowLink>
        </div>
      </div>
    </div>
  );
}
