import React, { ReactElement } from "react";
import { Form, Formik, FormikValues } from "formik";
import * as yup from "yup";
import { ArrowIcon } from "@ymcansw-camping/common";
import useSubmitFormCallback from "../hooks/useSubmitFormCallback";
import FormFieldInput from "./forms/form-field-input";
import FormStatus from "./forms/form-status";

const validationSchema = yup
  .object({
    emailAddress: yup
      .string()
      .email("Invalid email address")
      .required("Required"),
  })
  .required();

export default function NewsletterForm(): ReactElement {
  const onSubmit = useSubmitFormCallback("newsletter");

  return (
    <div
      id="newsletter-form"
      className="content-block with-border-top newsletter-block"
    >
      <h3 className="h4 mt0">Don&apos;t miss out!</h3>
      <p>
        Subscribe to our newsletter to be the first to hear about upcoming
        holiday camps.
      </p>
      <Formik
        initialValues={{ emailAddress: "" } as FormikValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form
            className="newsletter-form"
            name="newsletter"
            data-netlify="true"
          >
            <div className="row">
              <FormFieldInput
                className="col-xs-12 col-sm-8"
                name="emailAddress"
                type="email"
                placeholder="Your email address"
                disabled={isSubmitting}
              />
              <div className="col-xs-12 col-sm-4">
                <FormStatus success="Your subscription has been sent, thank you!" />
                <button
                  className="btn bg-red btn-medium with-icon icon-right with-hover"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Subscribing..." : "Subscribe"} <ArrowIcon />
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
